<template>

  <!-- Direct Costs -->
  <div id="story-mode-direct-costs">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">
      <loader v-if="!loaded.directCosts" />

      <!-- Content -->
      <div
        v-if="loaded.directCosts"
        class="content"
        :class="{ 'position-relative': isDirectCostsCompleted }"
      >
        <v-app>
          <!-- Page Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': isDirectCostsCompleted }"
              :title="$t('pages.businessGuide.directCosts.directCosts')"
              :guide-visible="guideVisible"
              :completed-step-name="'directCostsCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="guideVisible = true"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
          <!-- /Divider -->

          </header>
        <!-- /Page Header -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Charts And Table Placeholders -->
        <div v-if="isDirectCostsEmpty" class="table-view">
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div class="switcher-inner-wrapper">
                <div class="placeholder breakdown" />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div class="switcher-inner-wrapper">
                <div class="placeholder timeline" />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div class="switcher-inner-wrapper">
                <div class="placeholder table" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
        </div>
        <!-- /Charts And Table Placeholders -->

        <!-- Charts And Table Filled -->
        <div v-if="!isDirectCostsEmpty" class="table-view filled">
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div v-if="activeName === 'first'" class="switcher-inner-wrapper">
                <distribution-of-direct-costs
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div v-if="activeName === 'second'" class="bg-white">
                <direct-costs-over-time
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div v-if="activeName === 'third'" class="switcher-inner-wrapper">
                <direct-costs-table class="pt-4" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
        </div>
        <!-- /Charts And Table Filled -->

        <v-app><!-- Header -->
          <business-guide-header>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />
              <business-guide-search
                v-if="!isDirectCostsEmpty"
                v-model="searchValue"
                class="mx-1"
              />
              <!-- View Switcher -->
              <view-switcher
                v-if="isDirectCostsEmpty === false"
                :active-view="$store.state.itemsView.directCostsView"
                @activate-view="onActivateView"
              />
              <!-- /View Switcher -->
            </template>
          </business-guide-header>
          <!-- /Header --></v-app>

        <!-- List Wrapper -->
        <div
          v-if="$store.state.itemsView.directCostsView === 'list'"
          class="list-wrapper"
          :class="{ 'active': $store.state.itemsView.directCostsView === 'list' }"
        >
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isDirectCostsCompleted"
              @click="checkDirectCosts"
            />
            <!-- /Completed Overlay -->
            <!-- Add Direct Cost -->
            <el-col :md="12" class="d-flex mb-2">
              <add-new-card
                class="width-xl-20 py-2 py-md-0"
                :title="$tc('addDirectCost')"
                :disabled="!canEdit || isDirectCostsCompleted"
                horizontal
                @click="onAddDirectCost"
              />
            </el-col>
            <!-- /Add Direct Cost -->

            <!-- Placeholder 1 -->
            <el-col v-if="isDirectCostsEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 1 -->

            <!-- Placeholder 2 -->
            <el-col v-if="isDirectCostsEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 2 -->

            <!-- Placeholder 3 -->
            <el-col v-if="isDirectCostsEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder yellow">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 3 -->

            <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
            <tutorial-tooltip
              v-if="$store.state.user.tutorial.mainBottomBarCompleted === false && 1 === 2"
              :title="'Add customers you want to target'"
              :text="'This is the star of <strong>your show</strong>! Lorem ipsum dolor sit amet, consectetur, adipiscing elit praesent.'"
              :tutorial-name="'directCostsCompleted'"
            />
            <!-- /Tutorial Tooltip -->

            <!-- List Items -->
            <el-col
              v-for="directCost in directCostsToShowList"
              :key="directCost.id"
              class="d-flex mb-2"
              :md="12"
            >
              <div class="d-flex w-100" @click="checkDirectCosts">
                <set-up-and-projections-card
                  class="w-100"
                  :entity="directCost"
                  :name="directCost.name"
                  :date="`${getFormattedDate(directCost.starts)} - ${getFormattedDate(directCost.ends)}`"
                  :amount="getFormattedAmount(directCost.average, 2)"
                  :frequency="$t('pages.businessGuide.monthlyAverage')"
                  type="direct-cost"
                  :disabled="isDirectCostsCompleted"
                  view="list"
                  @edit="onEditDirectCost"
                  @copy="onCopyDirectCost"
                  @delete="onDeleteDirectCost"
                />
              </div>
            </el-col>
            <!-- /List Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="directCostItems.length > 7">
            <el-col>
              <show-all-toggle
                :show="showAllDirectCostsList"
                @click="showAllDirectCostsList = !showAllDirectCostsList"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /List Wrapper -->

        <!-- Grid Wrapper -->
        <div
          v-if="$store.state.itemsView.directCostsView === 'grid'"
          class="grid-wrapper"
          :class="{ 'active': $store.state.itemsView.directCostsView === 'grid' }"
        >
          <el-row>
            <el-col>
              <el-row :gutter="20" class="d-flex flex-wrap">
                <!-- Completed Overlay -->
                <completed-overlay
                  v-if="!canEdit || isDirectCostsCompleted"
                  @click="checkDirectCosts"
                />
                <!-- /Completed Overlay -->
                <!-- Add Direct Cost -->
                <el-col :md="8" :xl="6" class="d-flex position-relative width-lg-20 mb-4">
                  <add-new-card
                    class="width-xl-20"
                    :title="$tc('addDirectCost')"
                    :disabled="!canEdit || isDirectCostsCompleted"
                    @click="onAddDirectCost"
                  />
                </el-col>
                <!-- /Add Direct Cost -->

                <!-- Grid Items -->
                <el-col
                  v-for="directCost in directCostsToShowGrid"
                  :key="directCost.id"
                  class="d-flex mb-4 width-lg-20"
                  :md="8"
                  :xl="6"
                >
                  <div class="d-flex w-100" @click="checkDirectCosts">
                    <set-up-and-projections-card
                      class="w-100"
                      :entity="directCost"
                      :name="directCost.name"
                      :date="`${getFormattedDate(directCost.starts)} - ${getFormattedDate(directCost.ends)}`"
                      :amount="getFormattedAmount(directCost.average, 2)"
                      :frequency="$t('pages.businessGuide.monthlyAverage')"
                      type="direct-cost"
                      :disabled="isDirectCostsCompleted"
                      @edit="onEditDirectCost"
                      @copy="onCopyDirectCost"
                      @delete="onDeleteDirectCost"
                    />
                  </div>
                </el-col>
                <!-- /Grid Items -->
              </el-row>
              <!-- Show All -->
              <el-row v-if="directCostItems.length > 4">
                <el-col>
                  <show-all-toggle
                    :show="showAllDirectCostsGrid"
                    @click="showAllDirectCostsGrid = !showAllDirectCostsGrid"
                  />
                </el-col>
              </el-row>
              <!-- /Show All -->
            </el-col>
          </el-row>
        </div>
        <!-- /Grid Wrapper -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'forecast'"
        :step="'direct-costs'"
        :concept-name="'directCostsMoreDetails'"
        :completed-step-name="'directCostsCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'directCostsCompleted'"
      :step-filled="directCostItems.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="directCostsExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="direct-costs"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Direct Cost -->
    <dialog-add-direct-cost
      v-if="loaded.directCosts || dialogAddDirectCostVisible"
      :dialog-add-direct-cost-visible="dialogAddDirectCostVisible"
      :direct-cost-action="directCostAction"
      @close-dialog-add-direct-cost="onCloseDialogAddDirectCost"
      @close-dialog-on-button="dialogAddDirectCostVisible = false"
      @on-update-direct-streams-view="getDirectCosts"
    />
    <!-- /Dialog Add Direct Cost -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Direct Costs -->

</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddDirectCost from './Dialogs/DialogAddDirectCost'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import DirectCostsOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/DirectCost/DirectCostsOverTime'
import DirectCostsTable from './Tables/DirectCostsTable'
import DistributionOfDirectCosts from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/DirectCost/DistributionOfDirectCosts'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters, mapState } from 'vuex'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'DirectCosts',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessGuideSearch,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddDirectCost,
    DialogProcessCompleted,
    DialogRemoveExamples,
    DirectCostsOverTime,
    DirectCostsTable,
    DistributionOfDirectCosts,
    EraseIconButton,
    Loader,
    MainBottomBar,
    MainNavigation,
    SetUpAndProjectionsCard,
    ShowAllToggle,
    ViewSwitcher
  },

  mixins: [
    MixinApiCalculations,
    MixinCurrencies,
    MixinProcessCompleted,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate,
    MixinUserSubscription
  ],

  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     if (vm.subscriptionIsFreeTrial) next({ name: 'home' })
  //   })
  // },

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      activeName: 'first',
      dialogAddDirectCostVisible: false,
      directCostAction: null,
      guideVisible: false,
      showAllDirectCostsGrid: false,
      showAllDirectCostsList: false,
      dialogProcessCompletedVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    ...mapGetters('idea', {
      directCosts: 'getDirectCosts',
      getStepIsCompleted: 'getStepIsCompleted',
      getHasItemsFromTemplate: 'getHasItemsFromTemplate'
    }),

    directCostItems () {
      return this.idea.storyMode.forecast.directCosts.directCosts
    },

    isDirectCostsEmpty () {
      return !this.directCosts.length
    },

    isDirectCostsCompleted () {
      return this.getStepIsCompleted('directCostsCompleted')
    },

    directCostsToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterDirectCosts
      }

      return this.showAllDirectCostsGrid === true
        ? this.directCosts
        : this.directCosts.slice(0, 4)
    },

    directCostsToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterDirectCosts
      }

      return this.showAllDirectCostsList === true
        ? this.directCosts
        : this.directCosts.slice(0, 7)
    },

    filterDirectCosts () {
      return this.directCosts.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    ideaStartingFrom () {
      return this.idea.ideaSettings.startingFrom
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getDirectCosts')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.revenueStreams'),
        route: 'business-guide-projections-revenue-streams'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.marketingBudget'),
        route: 'business-guide-projections-marketing-budget'
      }
    }
  },

  watch: {
    ideaStartingFrom () {
      this.getDirectCosts()
    }
  },

  async created () {
    await this.getDirectCosts()
    await this.getDirectCostsMoreDetails()
    this.openGuide('directCostGuide')
  },

  methods: {
    ...mapActions('idea', [
      'getDirectCostsMoreDetails',
      'deleteStoryModeDirectCost',
      'deleteIdeaExamples'
    ]),

    checkDirectCosts () {
      if (!this.canEdit) return
      if (this.isDirectCostsCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    onCloseDialogAddDirectCost () {
      this.dialogAddDirectCostVisible = false
      this.directCostAction = null

      // Workaround to update computed property startupCostsToShowGrid & startupCostsToShowList
      this.updateDirectCostsView()
    },

    onAddDirectCost () {
      if (!this.canEdit) return
      this.dialogAddDirectCostVisible = true
    },

    updateDirectCostsView () {
      this.showAllDirectCostsList = !this.showAllDirectCostsList
      this.showAllDirectCostsList = !this.showAllDirectCostsList
      this.showAllDirectCostsGrid = !this.showAllDirectCostsGrid
      this.showAllDirectCostsGrid = !this.showAllDirectCostsGrid
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setDirectCostsView', view)
    },

    onEditDirectCost (directCost) {
      this.directCostAction = Object.assign({}, directCost)
      this.dialogAddDirectCostVisible = true
    },

    onCopyDirectCost (directCost) {
      this.directCostAction = Object.assign({}, directCost)
      this.directCostAction.id = 0
      this.dialogAddDirectCostVisible = true
    },

    onDeleteDirectCost (directCost) {
      this.loaded.directCosts = false
      this.deleteStoryModeDirectCost(directCost.id)
        .then((response) => {
          this.getDirectCosts()
        })

      // Return to list view, if there are no directCosts
      if (this.directCostItems.length === 0) {
        this.onActivateView('list')
      }
    },
    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    onEraseFromTemplate () {
      if (this.isDirectCostsCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('direct-costs')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.getDirectCosts()
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
  #story-mode-direct-costs {
    margin-left: 0;
  }
</style>
